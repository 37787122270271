import React from "react";
import Card from "react-bootstrap/Card";

import styles from "./index.module.scss";

interface Props {
  title: string;
  image?: string;
  children: JSX.Element | JSX.Element[];
  height: string;
  maxHeight: string;
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
}

export default function InfoCard(props: Props): JSX.Element {
  return (
    <Card
      style={{
        height: props.height,
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth ? props.maxWidth : undefined,
        minWidth: props.minWidth ? props.minWidth : undefined,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <Card.Header style={{ backgroundColor: "white" }}>
        <Card.Title className={styles.title}>
          {props.title}
        </Card.Title>
      </Card.Header>
      <Card.Body className={styles.body} style={{padding: 0}}>
        <div
          className={styles.container}
          style={{
            backgroundImage: props.image
              ? "linear-gradient(to right, white 30%, transparent 75%), url(" +
                props.image +
                ")"
              : "",
            padding: props.padding ? props.padding : 0,
          }}
        >
          {props.children}
        </div>
      </Card.Body>
    </Card>
  );
}
