import React from "react";
import Card from "react-bootstrap/Card";

import styles from "./index.module.scss";

interface Props {
  title: string;
  image: string;
  children: any;
}

export default function InfoCardColumn(props: Props): JSX.Element {
  return (
    <Card style={{ height: "100%", overflow: "hidden" }}>
      <Card.Header style={{ backgroundColor: "white", height: "80px" }}>
        <Card.Title className={styles.title}>{props.title}</Card.Title>
      </Card.Header>
      <Card.Body className={styles.body}>
        <div className={styles.container}>{props.children}</div>
      </Card.Body>
    </Card>
  );
}
