export enum DataState {
  INIT = "init",
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface IDataFetchStatus {
  state: DataState;
  message: string;
}

export interface IFetchedData {
  status: IDataFetchStatus;
  data?: any;
}
