import React, { useEffect, useState } from "react";

import Index from "../info-card";
import InfoValue from "../info-value";
import InfoUpdate from "../info-update";

interface Props {
  preparing: number | undefined;
  charging: number | undefined;
  connected: number | undefined;
  updateIdx: number | undefined;
}

interface ChargeDiffs {
  preparing: number; // Store previous value
  preparingDiff: number; // Store current difference
  charging: number;
  chargingDiff: number;
  connected: number;
  connectedDiff: number;
}

export default function ChargerActivity(props: Props): JSX.Element {
  const [_updateIdx, _setUpdateIdx] = useState<number | undefined>(undefined);
  const [diffs, setDiffs] = useState<ChargeDiffs | undefined>(undefined);

  useEffect(() => {
    if (props.updateIdx !== undefined && _updateIdx !== props.updateIdx) {
      if (props.updateIdx !== 0) {
        // Bypass the first update
        setDiffs((prev: ChargeDiffs | undefined) => {
          return {
            preparingDiff:
              prev && props.preparing ? props.preparing - prev.preparing : 0,
            preparing: props.preparing,

            chargingDiff:
              prev && props.charging ? props.charging - prev.charging : 0,
            charging: props.charging,

            connectedDiff:
              prev && props.connected ? props.connected - prev.connected : 0,
            connected: props.connected,
          } as ChargeDiffs;
        });
      }
      _setUpdateIdx(props.updateIdx);
    }
  }, [props, diffs, _updateIdx]);

  let all: number = 0;
  if (props.preparing && props.charging && props.connected) {
    all = props.preparing + props.charging + props.connected;
  }

  let preparingDiff = diffs?.preparingDiff ? (
    <div>
      (
      {diffs.preparingDiff > 0
        ? "+" + diffs.preparingDiff
        : diffs.preparingDiff}
      )
    </div>
  ) : (
    <div />
  );
  let chargingDiff = diffs?.chargingDiff ? (
    <div>
      ({diffs.chargingDiff > 0 ? "+" + diffs.chargingDiff : diffs.chargingDiff})
    </div>
  ) : (
    <div />
  );
  let connectedDiff = diffs?.connectedDiff ? (
    <div>
      (
      {diffs.connectedDiff > 0
        ? "+" + diffs.connectedDiff
        : diffs.connectedDiff}
      )
    </div>
  ) : (
    <div />
  );

  return (
    <Index
      title="Connector status"
      image={"/images/colors_2.png"}
      padding={"20px"}
      height={"40vh"}
      maxHeight={"350px"}
    >
      <InfoValue value={all} textAfter={"users"} />

      <InfoUpdate>
        <div>Preparing: {props.preparing}</div>
        {preparingDiff}
      </InfoUpdate>

      <InfoUpdate>
        <div>Charging: {props.charging}</div>
        {chargingDiff}
      </InfoUpdate>

      <InfoUpdate>
        <div>Connected: {props.connected}</div>
        {connectedDiff}
      </InfoUpdate>
    </Index>
  );
}
