import React from "react";
import { useKeycloak } from "@react-keycloak/web";

import logo from "./logo_spirii_white.png";
import styles from "./index.module.scss";

interface Props {
  label: string;
}

export default function Header(props: Props): JSX.Element {
  const { keycloak } = useKeycloak();

  return (
    <div
      className={styles.header}
      onClick={() => keycloak.logout()}
      style={{ backgroundImage: "url(/images/something_purple.png)" }}
    >
      <div className={styles.label}>{props.label}</div>

      <div className={styles.logo}>
        <img src={logo} alt="Logo" className={styles.image} />
      </div>
    </div>
  );
}
