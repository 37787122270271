import Keycloak from "keycloak-js";

// @ts-ignore
const keycloak: Keycloak.KeycloakInstance = new Keycloak({
  realm: "production",
  url: process.env.REACT_APP_AUTH_SERVER_URL,
  clientId: "dashboard-kpi"
});

export default keycloak;
