import React, { useEffect, useState } from "react";

import NocDashboard from "../../pages/noc-dashboard";
import SpiriiWorld from "../../pages/spirii-world";
import SpiriiKPI from "../../pages/spirii-kpi";

import { ROTATE_INTERVAL } from "../../config/config";

export default function Rotator(): JSX.Element {
  const PAGE_COUNT = 2;

  const [pageIdx, setPageIdx] = useState<number>(0);

  useEffect(() => {
    const timeout = window.setTimeout(
      () => setPageIdx(pageIdx >= PAGE_COUNT ? 0 : pageIdx + 1),
      ROTATE_INTERVAL
    );
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  });

  return (
    <div>
      <SpiriiWorld visible={pageIdx === 0} />
      <NocDashboard visible={pageIdx === 1} />
      <SpiriiKPI visible={pageIdx === 2} />
    </div>
  );
}
