import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

import NoDataNotice from "../alert";
import React from "react";

export interface IInfoBarChartData {
  idx: string;
  value: number;
}

interface IProps {
  visible: boolean;
  data: IInfoBarChartData[] | undefined;
}

export default function InfoBarChart(props: IProps): JSX.Element {
  if (!props.visible) {
    return <></>;
  } else {
    if (props.data) {
      return (
        <ResponsiveContainer width="90%" height="25%">
          <BarChart data={props.data}>
            <XAxis
              dataKey="idx"
              angle={75}
              height={40}
              tick={{ fill: "#494c4d" }}
              textAnchor="start"
            />
            <YAxis
              width={10}
              mirror={true}
              minTickGap={100}
              tick={{ fill: "#494c4d" }}
              tickFormatter={(t) => (t > 1000 ? Math.round(t / 1000) + "K" : t)}
            />
            <Bar dataKey="value" fill="#494c4d" />
          </BarChart>
        </ResponsiveContainer>
      );
    } else {
      return <NoDataNotice />;
    }
  }
}
