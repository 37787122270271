import NoDataNotice from "../alert";

import styles from "./index.module.scss";

interface IProps {
  label: string;
  growth: number | undefined;
  units: string;
}

export default function InfoGrowth(props: IProps): JSX.Element {
  if (props.growth !== undefined) {
    return (
      <div className={styles.growth}>
        <div className={styles.label}>{props.label}</div>
        <div className={styles.data}>
          {props.growth > 0 ? "+ " + props.growth : props.growth} {props.units}
        </div>
      </div>
    );
  } else {
    return <NoDataNotice />;
  }
}
