import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DateTime } from "luxon";

import AvailableChargers from "../../components/available-chargers";
import ChargerActivity from "../../components/charger-activity";
import ChargerProblems from "../../components/charger-problems";
import Header from "../../components/header";
import InfoLineChart from "../../components/info-line-chart";
import InfoCard from "../../components/info-card";
import NoDataNotice from "../../components/alert";
import Loading from "../../components/loading";

import useNocData from "../../hooks/UseNocData";

import {
  INocChargeData,
  INocPowerRow,
} from "../../interfaces/SpiriiNocData.interface";
import { DataState } from "../../interfaces/Data.interface";

interface IProps {
  visible: boolean;
}

export default function NocDashboard(props: IProps): JSX.Element {
  const marginTop = "2vh";
  const rowHeight = "40vh";
  const maxHeight = "350px";

  const chargeState: INocChargeData = useNocData();

  if (chargeState.status.state === DataState.SUCCESS && chargeState.data) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="NOC Dashboard" />
        <Container>
          <Row
            style={{
              marginTop: marginTop,
              height: rowHeight,
              maxHeight: maxHeight,
            }}
          >
            <Col>
              <AvailableChargers
                chargers={chargeState.data.totalChargers.COUNT}
                updateIdx={chargeState.updateIdx}
              />
            </Col>

            <Col>
              <ChargerActivity
                preparing={chargeState.data.preparing.PreparingCount}
                charging={chargeState.data.charging.ChargingCount}
                connected={chargeState.data.connected.ConnectedCount}
                updateIdx={chargeState.updateIdx}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "2vw", height: "40vh", maxHeight: "350px" }}>
            <Col>
              <ChargerProblems
                offline={chargeState.data.offline.OfflineCount}
                faulted={chargeState.data.faulted.FaultedCount}
              />
            </Col>

            <Col>
              <InfoCard
                title="Grid load"
                image={""}
                height={"40vh"}
                maxHeight={"350px"}
              >
                <InfoLineChart
                  visible={props.visible}
                  data={
                    chargeState.data.powerCollection
                      ? chargeState.data.powerCollection.map(
                          (powerRow: INocPowerRow) => {
                            return {
                              idx: DateTime.fromISO(
                                powerRow.collectedAt
                              ).toFormat("d/L HH:mm"),
                              value: powerRow.power,
                            };
                          }
                        )
                      : undefined
                  }
                />
              </InfoCard>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (chargeState.status.state === DataState.FAILURE) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="NOC Dashboard" />
        <Container style={{ height: "100vh" }}>
          <NoDataNotice />
        </Container>
      </div>
    );
  }

  if (chargeState.status.state === DataState.INIT) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="NOC Dashboard" />
        <Container style={{ height: "100vh" }}>
          <Loading />
        </Container>
      </div>
    );
  }

  return <div style={{ display: props.visible ? "" : "none" }} />;
}
