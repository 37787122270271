import React from "react";

import styles from "./index.module.scss";

interface IProps {
  children?: JSX.Element | JSX.Element[];
}

export default function InfoUpdate(props: IProps) {
  return (
    <div className={styles.update}>
      <div className={styles.row}>{props.children}</div>
    </div>
  );
}
