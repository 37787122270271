import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import React from "react";

import NoDataNotice from "../alert";

export interface IInfoLineChartData {
  idx: string;
  value: number;
}

interface IProps {
  visible: boolean;
  data?: IInfoLineChartData[];
}

export default function InfoLineChart(props: IProps): JSX.Element {
  if (!props.visible) {
    return <></>;
  } else {
    if (props.data && props.data.length > 0) {
      return (
        <ResponsiveContainer width="90%" height="90%">
          <LineChart
            width={500}
            height={300}
            data={props.data}
            margin={{
              top: 40,
              right: 5,
              left: 5,
              bottom: 60,
            }}
          >
            <XAxis
              dataKey="idx"
              angle={65}
              textAnchor="start"
              type={"category"}
            />
            <YAxis dataKey="value" label={{value: "Kw", position: "top", offset: 20}}/>
            <Line
              type="monotone"
              dataKey="value"
              stroke="#494c4d"
              strokeWidth={.5}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      );
    } else {
      return <NoDataNotice/>;
    }
  }
}
