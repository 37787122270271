import styles from "./index.module.scss";

interface IProps {
  image?: string;
}

export default function Divider(props: IProps): JSX.Element {
  const defaultImage = "/images/something_purple.png";

  return (
    <div
      className={styles.divider}
      style={{
        backgroundImage:
          "url(" + (props.image ? props.image : defaultImage) + ")",
      }}
    />
  );
}
