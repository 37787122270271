import React, { useEffect, useState } from "react";

import Index from "../info-card";
import InfoValue from "../info-value";
import InfoUpdate from "../info-update";

interface IProps {
  chargers: number | undefined;
  updateIdx: number | undefined;
}

interface IAvailable {
  chargers: number;
  diff: number;
}

export default function AvailableChargers(props: IProps): JSX.Element {
  const [_updateIdx, _setUpdateIdx] = useState<number | undefined>(undefined);
  const [available, setAvailable] = useState<IAvailable | undefined>(undefined);

  useEffect(() => {
    if (_updateIdx !== props.updateIdx) {
      if (props.updateIdx !== 0) {
        // Bypass the first update
        setAvailable((prev: IAvailable | undefined) => {
          return {
            chargers: props.chargers,
            diff: prev && props.chargers ? props.chargers - prev.chargers : 0,
          } as IAvailable;
        });
      }
      _setUpdateIdx(props.updateIdx);
    }
  }, [props, _updateIdx]);

  let difference: JSX.Element = <InfoUpdate />;

  if (available?.diff) {
    if (available.diff > 0) {
      difference = (
        <InfoUpdate>
          <div>{available.diff} were recently added</div>
        </InfoUpdate>
      );
    } else if (available.diff < 0) {
      difference = (
        <InfoUpdate>
          <div>{available.diff} were recently removed</div>
        </InfoUpdate>
      );
    }
  }

  return (
    <Index
      title="Total Charge Boxes"
      image={"/images/colors_1.png"}
      padding={"20px"}
      height={"40vh"}
      maxHeight={"350px"}
    >
      <InfoValue value={props.chargers} />
      {difference}
    </Index>
  );
}
