import styles from "./index.module.scss";

interface Props {
  header: string;
  children: JSX.Element | JSX.Element[];
}

export default function InfoColumnWithHeader(props: Props): JSX.Element {
  return (
    <div className={styles.column}>
      <div className={styles.header}>{props.header}</div>
      <div className={styles.internal}>
        {props.children}
      </div>
    </div>
  );
}
