import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import useSpiriiKPIData from "../../hooks/UseSpiriiKPIData";

import Header from "../../components/header";
import InfoValue from "../../components/info-value";
import InfoBarChart from "../../components/info-bar-chart";
import InfoGrowth from "../../components/info-growth";
import InfoCardColumn from "../../components/info-card-column";
import Divider from "../../components/divider";
import NoDataNotice from "../../components/alert";
import Loading from "../../components/loading";

import { DataState } from "../../interfaces/Data.interface";
import {
  IKPIStats,
  IKPIStatusAndData,
} from "../../interfaces/SpiriiKPIData.interface";

interface IProps {
  visible: boolean;
}

export default function SpiriiKPI(props: IProps) {
  const marginTop = "2vh";
  const rowHeight = "80vh";
  const maxHeight = "650px";

  const kpiData: IKPIStatusAndData = useSpiriiKPIData();

  if (kpiData.status.state === DataState.SUCCESS && kpiData.data) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="Spirii KPI" />
        <Container>
          <Row
            md={4}
            lg={4}
            xl={4}
            style={{
              marginTop: marginTop,
              height: rowHeight,
              maxHeight: maxHeight,
            }}
          >
            <Col>
              <InfoCardColumn title={"Spirii Charge Points"} image={""}>
                <InfoValue value={kpiData.data.spiriiChargePoints?.total} />
                <Divider />
                <InfoGrowth
                  growth={kpiData.data.spiriiChargePoints?.growth}
                  units={"%"}
                  label={"Last 30 days"}
                />
                <Divider />
                <InfoBarChart
                  visible={props.visible}
                  data={kpiData.data.spiriiChargePoints?.stats.map(
                    (row: IKPIStats) => {
                      return { idx: row.month, value: row.value };
                    }
                  )}
                />
              </InfoCardColumn>
            </Col>

            <Col>
              <InfoCardColumn title={"Spirii App Users"} image={""}>
                <InfoValue value={kpiData.data.spiriiAppUsers?.total} />
                <Divider />
                <InfoGrowth
                  growth={kpiData.data.spiriiAppUsers?.growth}
                  units={"%"}
                  label={"Last 30 days"}
                />
                <Divider />
                <InfoBarChart
                  visible={props.visible}
                  data={kpiData.data.spiriiAppUsers?.stats.map(
                    (row: IKPIStats) => {
                      return { idx: row.month, value: row.value };
                    }
                  )}
                />
              </InfoCardColumn>
            </Col>

            <Col>
              <InfoCardColumn title={"Charging Sessions"} image={""}>
                <InfoValue value={kpiData.data.chargingSessions?.total} />
                <Divider />
                <InfoGrowth
                  growth={kpiData.data.chargingSessions?.growth}
                  units={"%"}
                  label={"Last 30 days"}
                />
                <Divider />
                <InfoBarChart
                  visible={props.visible}
                  data={kpiData.data.chargingSessions?.stats.map(
                    (row: IKPIStats) => {
                      return { idx: row.month, value: row.value };
                    }
                  )}
                />
              </InfoCardColumn>
            </Col>

            <Col>
              <InfoCardColumn title={"MWh Consumed"} image={""}>
                <InfoValue
                  value={
                    kpiData.data.kWhConsumed?.total
                      ? Math.round(kpiData.data.kWhConsumed?.total / 1000)
                      : -1
                  }
                />
                <Divider />
                <InfoGrowth
                  growth={kpiData.data.kWhConsumed?.growth}
                  units={"%"}
                  label={"Last 30 days"}
                />
                <Divider />
                <InfoBarChart
                  visible={props.visible}
                  data={kpiData.data.kWhConsumed?.stats.map(
                    (row: IKPIStats) => {
                      return { idx: row.month, value: row.value };
                    }
                  )}
                />
              </InfoCardColumn>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (kpiData.status.state === DataState.FAILURE) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="Spirii KPI" />
        <Container>
          <NoDataNotice />
        </Container>
      </div>
    );
  }

  if (kpiData.status.state === DataState.INIT) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="Spirii KPI" />
        <Container style={{ height: "100vh" }}>
          <Loading />
        </Container>
      </div>
    );
  }

  // Fall through case...
  return <div style={{ display: props.visible ? "" : "none" }} />;
}
