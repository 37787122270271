import React from "react";

import NoDataNotice from "../alert";

import styles from "./index.module.scss";

interface IProps {
  value: number | undefined;
  textBefore?: string;
  textAfter?: string;
}

export default function InfoValue(props: IProps): JSX.Element {
  const nf = Intl.NumberFormat();

  if (props.value !== undefined) {
    return (
      <div className={styles.value}>
        {props.textBefore} {nf.format(props.value!)} {props.textAfter}
      </div>
    );
  } else {
    return <NoDataNotice />;
  }
}
