import { useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import useMapData from "../../hooks/UseMapData";

import { IMapLocation } from "../../interfaces/Map.interface";
import { IFetchedData } from "../../interfaces/Data.interface";

import styles from "./index.module.scss";

const ACCESS_TOKEN =
  "pk.eyJ1IjoiZGFuaWVsZHJlamVyIiwiYSI6ImNra3NpNDZsZDBzajkydm82cDA3ZmtpNXkifQ.B14Ega5HiI7Wele1EoOJSg";

export default function Map(): JSX.Element {
  const mapContainer: any = useRef<HTMLElement>(null);
  const map: any = useRef<mapboxgl.Map>(null);
  const markers: IFetchedData = useMapData();

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/danieldrejer/ckgkpmc8z0gaa19ljst6igvfv",
      accessToken: ACCESS_TOKEN,
      zoom: 3,
      bounds: [-15.0, 30.0, 30.0, 72.0],
    });
  }, []);

  useEffect(() => {
    if (markers.data) {
      (markers.data as IMapLocation[]).forEach((t: IMapLocation) => {
        const el = document.createElement("div");
        el.className = styles.marker;
        el.innerText = t.count.toFixed(0);

        new mapboxgl.Marker(el)
          .setLngLat([t.longitude, t.latitude])
          .addTo(map.current);
      });
    }
  }, [markers]);

  return <div ref={mapContainer} style={{ width: "100%", height: "100%" }} />;
}
