import useTimedFetch from "./UseTimedFetch";

import { IFetchedData } from "../interfaces/Data.interface";
import {
  IRawCountAmount,
  ISpiriiWorldData,
  ISpiriiWorldDataDetails,
  ISpiriiWorldRawData,
  ISpiriiWorldStatusAndData,
} from "../interfaces/SpiriiWorldData.interface";

function convertCategory(
  all: IRawCountAmount[] | undefined,
  lastMonth: IRawCountAmount[] | undefined
): ISpiriiWorldDataDetails {
  let _growthLatestMonth: number | undefined = undefined;

  if (all !== undefined && lastMonth !== undefined) {
    let _sumUntilLastMonth = all[0].COUNT - lastMonth[0].COUNT;
    /* Diff should be bigger than 0 to not get Infinite number results */
    if (!(_sumUntilLastMonth > 0)) {
      console.error(
        "useSpiriiWorldData.convertCategory will fail because of division by zero, " +
          "will base on 'all' for now"
      );
      _sumUntilLastMonth = all[0].COUNT;
    }
    /* Growth in percentage based on the sum until last month */
    _growthLatestMonth = Math.round(
      (lastMonth[0].COUNT * 100) / _sumUntilLastMonth
    );
  }

  return {
    count: all ? all[0].COUNT : undefined,
    growthLastMonth: _growthLatestMonth,
  };
}

function convertData(rawData: ISpiriiWorldRawData): ISpiriiWorldData {
  // console.log("useSpiriiWorldData.convertData", rawData);
  return {
    spiriiChargePoints: convertCategory(
      rawData.chargePoints,
      rawData.chargePointsLastMonth
    ),
    privateChargePoints: convertCategory(
      rawData.privateChargePoints,
      rawData.privateChargePointsLastMonth
    ),
    businessChargePoints: convertCategory(
      rawData.businessChargePoints,
      rawData.businessChargePointsLastMonth
    ),
    totalChargePoints: convertCategory(
      rawData.totalChargePoints,
      rawData.totalChargePointsLastMonth
    ),

    spiriiCPOSessions: convertCategory(
      rawData.CPOSessions,
      rawData.CPOSessionsLastMonth
    ),
    spiriiEMPSessions: convertCategory(
      rawData.EMPSessions,
      rawData.EMPSessionsLastMonth
    ),
    totalChargingSession: convertCategory(
      rawData.totalChargeSessionsIncRoaming,
      rawData.totalChargeSessionsIncRoamingLastMonth
    ),

    chargePoints: [],
  };
}

export default function useSpiriiWorldData(): ISpiriiWorldStatusAndData {
  const rawData: IFetchedData = useTimedFetch("/world");

  return {
    status: rawData.status,
    data: rawData.data
      ? convertData(rawData.data.dashboardSpiriiWorld as ISpiriiWorldRawData)
      : undefined,
  } as ISpiriiWorldStatusAndData;
}
