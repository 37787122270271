import React from "react";

import Index from "../info-card";
import InfoUpdate from "../info-update";

interface Props {
  offline: number | undefined;
  faulted: number | undefined;
}

export default function ChargerProblems(props: Props): JSX.Element {
  return (
    <Index
      title="Charge Box Issues"
      image={"/images/colors_3.png"}
      padding={"20px"}
      height={"40vh"}
      maxHeight={"350px"}
    >
      <InfoUpdate>
        <div>Offline Charge Boxes: {props.offline}</div>
      </InfoUpdate>

      <InfoUpdate>
        <div>Connector faults: {props.faulted}</div>
      </InfoUpdate>
    </Index>
  );
}
