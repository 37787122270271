import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import _ from "lodash";

import { REFRESH_INTERVAL } from "../config/config";

import { DataState, IFetchedData } from "../interfaces/Data.interface";

function getData(
  url: string,
  initialized: boolean,
  kc: Keycloak.KeycloakInstance
): Promise<any> {
  if (!initialized || !kc.token) {
    console.error(
      "UseTimedFetch: Keycloak not initialized or undefined token",
      kc
    );
    return Promise.resolve({
      status: { state: DataState.FAILURE, message: "Keycloak not ready" },
    } as IFetchedData);
  }

  return fetch(url, { headers: { Authorization: "Bearer " + kc.token } })
    .then((response: Response) => {
      if (!response.ok) {
        throw new Error("Service failed to deliver");
      }
      return response.json();
    })
    .then((data: any) => {
      return {
        status: { state: DataState.SUCCESS, message: "" },
        data: data,
      } as IFetchedData;
    })
    .catch((e) => {
      console.error("getData.fetch failed", e);
      return {
        status: { state: DataState.FAILURE, message: "No data" },
      } as IFetchedData;
    });
}

export default function useTimedFetch(endPoint: string, interval?: number) {
  const url: string | undefined = process.env.REACT_APP_API_ENDPOINT;
  const [data, setData] = useState<IFetchedData>({
    status: { state: DataState.INIT, message: "" },
  });

  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    const _getData = () => {
      getData(url + endPoint, initialized, keycloak).then(
        (res: IFetchedData) => {
          setData((prev: IFetchedData) => (_.isEqual(prev, res) ? prev : res));
        }
      );
    };

    _getData();
    const tmp = window.setInterval(
      () => _getData(),
      interval ? interval : REFRESH_INTERVAL
    );
    return () => window.clearInterval(tmp);
  }, [url, endPoint, interval, keycloak, initialized]);

  return data;
}
