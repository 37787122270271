import React from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";

import Rotator from "../rotator/Rotator";
import NocDashboard from "../../pages/noc-dashboard";
import SpiriiKPI from "../../pages/spirii-kpi";
import SpiriiWorld from "../../pages/spirii-world";

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact={false} path="/dashboard">
          <Rotator />
        </Route>

        <Route exact={false} path="/noc-dashboard">
          <NocDashboard visible={true} />
        </Route>

        <Route exact={false} path="/spirii-kpi">
          <SpiriiKPI visible={true} />
        </Route>

        <Route exact={false} path="/spirii-world">
          <SpiriiWorld visible={true} />
        </Route>

        <Route path="*">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
