import { useRef } from "react";

import useTimedFetch from "./UseTimedFetch";

import { IFetchedData } from "../interfaces/Data.interface";
import { INocChargeData } from "../interfaces/SpiriiNocData.interface";

export default function useNocData() {
  const updateCountRef = useRef(0);
  const rawData: IFetchedData = useTimedFetch("/noc");

  updateCountRef.current++;

  return {
    status: rawData.status,
    updateIdx: updateCountRef.current,
    data: rawData.data?.dashboardNOC,
  } as INocChargeData;
}
