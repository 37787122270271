import { DateTime } from "luxon";

import useTimedFetch from "./UseTimedFetch";

import { IFetchedData } from "../interfaces/Data.interface";
import {
  IKPIData,
  IKPIRawData,
  IKPIRawDataCount,
  IKPIStatCategory,
  IKPIStats,
  IKPIStatusAndData,
} from "../interfaces/SpiriiKPIData.interface";

function sortLatestFirst(a: IKPIRawDataCount, b: IKPIRawDataCount): number {
  return a.YEARCREATED < b.YEARCREATED
    ? 1
    : a.YEARCREATED > b.YEARCREATED
    ? -1
    : a.MONTHCREATED < b.MONTHCREATED
    ? 1
    : a.MONTHCREATED > b.MONTHCREATED
    ? -1
    : 0;
}

function convertCategory(
  all: { COUNT: number }[],
  perMonth: IKPIRawDataCount[],
  lastMonth: { COUNT: number }[]
): IKPIStatCategory | undefined {
  if (!all || !perMonth || !lastMonth) {
    return undefined;
  }

  perMonth.sort(sortLatestFirst);
  let _total: number | undefined = all[0]?.COUNT;
  _total = _total ? Math.round(_total) : undefined;

  const _addedLatestMonth: number | undefined = lastMonth[0].COUNT;
  /* Growth in percentage based on the sum until last month, try to avoid div by zero */
  const _growthLatestMonth: number | undefined =
    _addedLatestMonth && _total && _total - _addedLatestMonth
      ? Math.round((_addedLatestMonth * 100) / (_total - _addedLatestMonth))
      : 0;
  /* Only show the last 12 months */
  const _stats: IKPIStats[] = perMonth
    .slice(0, 12)
    .reverse()
    .map((val: IKPIRawDataCount) => {
      return {
        year: val.YEARCREATED,
        month: DateTime.fromObject({ month: val.MONTHCREATED }).toLocaleString({
          month: "short",
        }),
        value: val.COUNT,
      };
    });
  return {
    total: _total,
    growth: _growthLatestMonth,
    stats: _stats,
  } as IKPIStatCategory;
}

function convertData(dashboardKPI: IKPIRawData): IKPIData {
  return {
    spiriiChargePoints: convertCategory(
      dashboardKPI.allChargers,
      dashboardKPI.allChargersPerMonth,
      dashboardKPI.allChargersLastMonth
    ),
    spiriiAppUsers: convertCategory(
      dashboardKPI.allRegisteredUsers,
      dashboardKPI.allRegisteredUsersPerMonth,
      dashboardKPI.allRegisteredUsersLastMonth
    ),
    chargingSessions: convertCategory(
      dashboardKPI.allTransactions,
      dashboardKPI.allTransactionsPerMonth,
      dashboardKPI.allTransactionsLastMonth
    ),
    kWhConsumed: convertCategory(
      dashboardKPI.allKW,
      dashboardKPI.allKWPerMonth,
      dashboardKPI.allKWLastMonth
    ),
  };
}

export default function useSpiriiKPIData(): IKPIStatusAndData {
  const rawData: IFetchedData = useTimedFetch("/kpi");

  return {
    status: rawData.status,
    data: rawData.data
      ? convertData(rawData.data.dashboardKPI as IKPIRawData)
      : undefined,
  } as IKPIStatusAndData;
}
