import useTimedFetch from "./UseTimedFetch";

import { IFetchedData } from "../interfaces/Data.interface";
import { IMapLocation, IMapRawData } from "../interfaces/Map.interface";

function convertData(iData: IMapRawData[]): IMapLocation[] {
  return iData.map((dta: IMapRawData) => {
    return {
      type: dta.type,
      longitude: dta.geometry.coordinates[0],
      latitude: dta.geometry.coordinates[1],
      count: dta.properties.point_count ? dta.properties.point_count : 1,
    } as IMapLocation;
  });
}

export default function useMapData() {
  const rawData: IFetchedData = useTimedFetch(
    "/map?zoom=3&" +
      "boundsSw=40.0%2C-20.0&" +
      "boundsNe=70.0%2C35.0&" +
      "roaming=true",
    3600000 /* an hour in ms */
  );

  return {
    status: rawData.status,
    data: rawData.data ? convertData(rawData.data.map) : undefined,
  };
}
