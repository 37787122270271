import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AuthClientInitOptions } from "@react-keycloak/core/lib/types";

import keycloak from "./components/keycloak/Keycloak";
import Loading from "./components/loading";
import App from "./components/app";

import "./index.scss";

const keycloak_init_options: AuthClientInitOptions = {
  onLoad: "login-required",
  pkceMethod: "S256",
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloak_init_options}
    LoadingComponent={<Loading />}
  >
    <App />
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
