import { Spinner } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import styles from "./index.module.scss";

interface IBall {
  top: number;
  left: number;
  hidden: boolean;
}

export default function Loading() {
  const divRef: any = useRef(null);
  let updateIdx: any = useRef(0);

  const [balls, setBalls] = useState<IBall[]>([
    { top: 0, left: 0, hidden: true },
    { top: 0, left: 0, hidden: true },
    { top: 0, left: 0, hidden: true },
    { top: 0, left: 0, hidden: true },
    { top: 0, left: 0, hidden: true },
    { top: 0, left: 0, hidden: true },
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      let test: IBall[] = [...balls];

      const _hCenter = divRef.current?.clientWidth / 2;
      const _vCenter = divRef.current?.clientHeight / 2;

      test[0].top = _vCenter - 100;
      test[0].left = _hCenter - 100;

      test[1].top = _vCenter + 100;
      test[1].left = _hCenter + 100;

      test[2].top = _vCenter + 75;
      test[2].left = _hCenter - 75;

      test[3].top = _vCenter - 50;
      test[3].left = _hCenter + 100;

      test[4].top = _vCenter - 75;
      test[4].left = _hCenter + 50;

      test[5].top = _vCenter + 10;
      test[5].left = _hCenter - 10;

      test[updateIdx.current].hidden = !test[updateIdx.current].hidden;
      setBalls(test);
      if (updateIdx.current < 5) {
        updateIdx.current++;
      } else {
        updateIdx.current = 0;
      }
    }, 1000);

    return () => clearInterval(intervalId);
  });

  return (
    <div ref={divRef} style={{ height: "100%", width: "100%" }}>
      <Spinner
        animation="grow"
        hidden={balls[0].hidden}
        className={styles.loadingBall1}
        style={{ top: balls[0].top + "px", left: balls[0].left + "px" }}
      />
      <Spinner
        animation="grow"
        size={"sm"}
        hidden={balls[1].hidden}
        className={styles.loadingBall1}
        style={{ top: balls[1].top + "px", left: balls[1].left + "px" }}
      />

      <Spinner
        animation="grow"
        hidden={balls[2].hidden}
        className={styles.loadingBall2}
        style={{ top: balls[2].top + "px", left: balls[2].left + "px" }}
      />
      <Spinner
        animation="grow"
        size={"sm"}
        hidden={balls[3].hidden}
        className={styles.loadingBall2}
        style={{ top: balls[3].top + "px", left: balls[3].left + "px" }}
      />

      <Spinner
        animation="grow"
        hidden={balls[4].hidden}
        className={styles.loadingBall3}
        style={{ top: balls[4].top + "px", left: balls[4].left + "px" }}
      />
      <Spinner
        animation="grow"
        size={"sm"}
        hidden={balls[5].hidden}
        className={styles.loadingBall3}
        style={{ top: balls[5].top + "px", left: balls[5].left + "px" }}
      />
    </div>
  );
}
