import React from "react";
import { Alert } from "react-bootstrap";

export default function NoDataNotice() {
  return (
    <Alert variant={"warning"}>
      <Alert.Heading>Oh darn!</Alert.Heading>
      No data received from service...
    </Alert>
  );
}
