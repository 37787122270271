import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import useSpiriiWorldData from "../../hooks/UseSpiriiWorldData";

import Header from "../../components/header";
import InfoCard from "../../components/info-card";
import Map from "../../components/map";
import NoDataNotice from "../../components/alert";
import InfoValue from "../../components/info-value";
import InfoGrowth from "../../components/info-growth";
import Divider from "../../components/divider";
import Loading from "../../components/loading";
import InfoColumnWithHeader from "../../components/info-column-with-header";

import { DataState } from "../../interfaces/Data.interface";
import { ISpiriiWorldStatusAndData } from "../../interfaces/SpiriiWorldData.interface";

interface IProps {
  visible: boolean;
}

export default function SpiriiWorld(props: IProps) {
  const marginTop = "2vh";
  const rowHeight = "86vh";
  const halfRowHeight = "42vh";
  const maxHeight = "800px";

  const spiriiWorldData: ISpiriiWorldStatusAndData = useSpiriiWorldData();

  if (
    spiriiWorldData.status.state === DataState.SUCCESS &&
    spiriiWorldData.data
  ) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="Spirii World" />
        <Container>
          <Row
            md={2}
            lg={2}
            xl={2}
            style={{
              marginTop: marginTop,
              height: rowHeight,
              maxHeight: maxHeight,
            }}
          >
            <Col md={8} lg={8} xl={8}>
              <Row style={{ flexWrap: "nowrap" }}>
                <InfoCard
                  title={"Charge Points"}
                  // minWidth={"55vw"}
                  image={""}
                  padding={"10px"}
                  height={halfRowHeight}
                  maxHeight={maxHeight}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      height: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <InfoColumnWithHeader header={"Spirii"}>
                      <InfoValue
                        value={spiriiWorldData.data.spiriiChargePoints?.count}
                      />
                      <Divider />
                      <InfoGrowth
                        label={"Last 30 days"}
                        growth={
                          spiriiWorldData.data.spiriiChargePoints
                            ?.growthLastMonth
                        }
                        units={"%"}
                      />
                    </InfoColumnWithHeader>

                    <InfoColumnWithHeader header={"Private"}>
                      <InfoValue
                        value={spiriiWorldData.data.privateChargePoints?.count}
                      />
                      <Divider />
                      <InfoGrowth
                        label={"Last 30 days"}
                        growth={
                          spiriiWorldData.data.privateChargePoints
                            ?.growthLastMonth
                        }
                        units={"%"}
                      />
                    </InfoColumnWithHeader>

                    <InfoColumnWithHeader header={"Business"}>
                      <InfoValue
                        value={spiriiWorldData.data.businessChargePoints?.count}
                      />
                      <Divider />
                      <InfoGrowth
                        label={"Last 30 days"}
                        growth={
                          spiriiWorldData.data.businessChargePoints
                            ?.growthLastMonth
                        }
                        units={"%"}
                      />
                    </InfoColumnWithHeader>

                    <InfoColumnWithHeader header={"Total incl. Roaming"}>
                      <InfoValue
                        value={spiriiWorldData.data.totalChargePoints?.count}
                      />
                    </InfoColumnWithHeader>
                  </div>
                </InfoCard>
              </Row>

              <Row style={{ marginTop: marginTop }}>
                <InfoCard
                  title={"Roaming Sessions"}
                  padding={"10px"}
                  // minWidth={"55vw"}
                  image={""}
                  height={halfRowHeight}
                  maxHeight={maxHeight}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      height: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <InfoColumnWithHeader header={"With Spirii as CPO"}>
                      <InfoValue
                        value={spiriiWorldData.data.spiriiCPOSessions?.count}
                      />
                      <Divider />
                      <InfoGrowth
                        label={"Last 30 days"}
                        growth={
                          spiriiWorldData.data.spiriiCPOSessions
                            ?.growthLastMonth
                        }
                        units={"%"}
                      />
                    </InfoColumnWithHeader>

                    <InfoColumnWithHeader header={"With Spirii as EMP"}>
                      <InfoValue
                        value={spiriiWorldData.data.spiriiEMPSessions?.count}
                      />
                      <Divider />
                      <InfoGrowth
                        label={"Last 30 days"}
                        growth={
                          spiriiWorldData.data.spiriiEMPSessions
                            ?.growthLastMonth
                        }
                        units={"%"}
                      />
                    </InfoColumnWithHeader>

                    <InfoColumnWithHeader header={"All Sessions"}>
                      <InfoValue
                        value={spiriiWorldData.data.totalChargingSession?.count}
                      />
                      <Divider />
                      <InfoGrowth
                        label={"Last 30 days"}
                        growth={
                          spiriiWorldData.data.totalChargingSession
                            ?.growthLastMonth
                        }
                        units={"%"}
                      />
                    </InfoColumnWithHeader>
                  </div>
                </InfoCard>
              </Row>
            </Col>
            <Col md={4} lg={4} xl={4}>
              <InfoCard
                title={"On the map"}
                image={""}
                height={rowHeight}
                maxHeight={maxHeight}
              >
                <Map />
              </InfoCard>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  if (spiriiWorldData.status.state === DataState.INIT) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="Spirii World" />
        <Container style={{ height: "100vh" }}>
          <Loading />
        </Container>
      </div>
    );
  }

  if (
    spiriiWorldData.status.state === DataState.FAILURE ||
    !spiriiWorldData.data
  ) {
    return (
      <div style={{ display: props.visible ? "" : "none" }}>
        <Header label="Spirii World" />
        <Container style={{ height: "100vh" }}>
          <NoDataNotice />
        </Container>
      </div>
    );
  }

  // Fall through case...
  return <div style={{ display: props.visible ? "" : "none" }} />;
}
